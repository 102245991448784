//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src
// import res from "./common/common";

// const wx = require('weixin-js-sdk');
import api from './networking/api'
import common from './common/common'
import './common/previewImage'
import { LocalStorage } from 'quasar'
export default {
  name: 'PlacesAdjustment',
  data() {
    return {
      showAllGroup:false,
      showTab:'images',
      showQuestionAll:false,
      api,
      current:0,
      loading: false,
      items: [],
      wxConfigData: null,
      assessId:'',
      totalScore:0,
      goodScore:0,
      userTotalScore:0,
      placeAssessAll:[],
      storeId: '',
      brand: '',
      actionId:''
    }
  },
  computed:{
    places(){
      return this.showItems && this.showItems[this.current] && this.showItems[this.current].places || []
    },
    scores(){
      return this.showItems && this.showItems[this.current] && this.showItems[this.current].scores && this.showItems[this.current].scores.filter(v=>{
        if(this.showQuestionAll){
          return true
        }else{
          return v.currentScore < v.score
        }
      }) || [] 
    },
    showTips(){
      let group = this.showItems && this.showItems[this.current];
      if(group && group.groupId){
        let badPhotoNum = group && group.places && group.places.length || 0;
        let photoNum = this.placeAssessAll.filter(v=>{
          return v.groupId == group.groupId
        }).length
        console.log(photoNum,group.groupMustUpload,badPhotoNum);
        if(photoNum == 0 && group.groupMustUpload == 1){
          // 没有照片 -位置组必传
          return '陈列重点关注未传图店铺，下次记得上传哦'
        }else if(photoNum == 0 && group.groupMustUpload == 0){
          // 没有照片-位置组非必传
          return '未上传照片'
        }else if(photoNum > 0 && badPhotoNum == 0 && group.groupMustUpload == 1){
          // 有照片 - 无问题
          return '无问题照片'
        }else{
          return ''
        }
      }else{
         // 无对应位置组
         return '无需上传照片'
      }
    },
    showItems(){
      return this.items && this.items.filter(item=>{
        if(this.showAllGroup){
          return true
        }else{
          let isQuestion = false
          if(item.scores && Array.isArray(item.scores)){
            item.questionScoreNum = 0
            item.questionScoreAllNum = 0
            item.scores.map(v=>{
              item.questionScoreAllNum ++
              if(v.currentScore < v.score){
                isQuestion = true
                item.questionScoreNum ++
              }
            })
          }
          if(item.places && item.places.length > 0){
            isQuestion  = true
          }
          return isQuestion
         
        }
      })
    }
  },
  watch: {
    showAllGroup(newer, older) {
      this.current = 0
    },
    current(newer, older) {
      document.getElementById('list-top-item' + newer).scrollIntoView()
    },
  },
  methods: {
    changeShowTab(val){
      this.showTab = val
    },
    getAssess(){
      let that = this;
      this.axios.get(`${api.getPlaceAssessById}?id=${this.assessId}`)
      .then(function (response) {
          that.storeId = response.data.data.storeId
          that.brand = response.data.data.brand
          that.actionId = response.data.data.actionId
          that.getAdjustment()
      })
    },
    getAdjustment: function() {
      let that = this;
      this.axios.get(`${api.getPlaceAssessItemAreaListByAssessId}?assessId=${this.assessId}`)
      .then(function (response) {
          console.log(response);
          that.totalScore = response.data.data.totalScore
          that.goodScore = parseInt(response.data.data.goodScore)
          that.userTotalScore = response.data.data.userTotalScore
          let items = []
          that.placeAssessAll = response.data.data.placeAssess || []
          let placeAssess = that.placeAssessAll.filter(v=>{
            return v.level == '3'
          }) || []
          placeAssess.sort(function (a, b) {
              return Number(a.placeSort) - Number(b.placeSort);
          })
          
          placeAssess.map(v =>{
            v.areaName = v.areaName  || ''
            let index = items.findIndex(item =>{
              return item.areaId === v.areaId
            })
            if(index > -1){
              items[index].places.push(v)
            }else{
              items.push({
                places:[v],
                scores:[],
                ...v
              })
            }
          })
          let areaScoreList = response.data.data.areaScoreList || []
          areaScoreList.map(v =>{
            v.areaName = v.areaName  || ''
            let index = items.findIndex(item =>{
              return item.areaId === v.areaId
            })
            if(index > -1){
              items[index].scores.push(v)
            }else{
              items.push({
                scores:[v],
                places:[],
                ...v
              })
            }
          })
          items.sort(function (a, b) {
            return Number(a.areaSort) - Number(b.areaSort);
          })
          that.items = items
          // if(response.data.data.list.length == 0){
          //   that.$toast({message: '调整完成', duration: 1000});
          //   setTimeout(() => {
          //     that.$router.go(-1)
          //   }, 1000);
          // }
      })
      .catch(function (error) {
          console.log(error);
          alert(error)
      })
      .then(function () {

      });
    },
    changeGroup(e){
      this.current = e
      this.showTab = 'images'
    },
    imgWatermark (src, text,index) {
      let _this = this
      let img = new Image();
      img.crossOrigin = "anonymous";
      img.src = src
      img.addEventListener('load',async () => {
        let canvas = await _this.imgToCanvas(img);
        let blob = await _this.watermark(canvas, text)
        let htmlImg = await _this.blobToImg(blob)
        _this.$set(_this.items[index],"image",htmlImg.getAttribute('src'))
      })  
    },
    watermark (canvas, text) {
      return new Promise((resolve, reject) => {
        let context = canvas.getContext('2d')
        // 设置填充字号和字体，样式
        let canvasWidth = context.canvas.width
        let canvasHeight = context.canvas.height
        context.font=`${canvasWidth/24}px microsoft yahei`;
        // 旋转45度
        context.rotate(-45 * Math.PI / 180);
        context.fillStyle = 'RGB(255, 255, 255,0.3)';
        
        for(var i = 0;i<12;i++){
          for(var j = 0;j<12;j++){
            context.fillText(text, (canvasWidth/4) * (6 - i) , (canvasHeight/4) * (6 - j)  )
          }
        }
        // 重置当前的变换矩阵为初始态
        context.setTransform(1, 0, 0, 1, 0, 0);
        canvas.toBlob(blob => resolve(blob))
      })
    },
    blobToImg (blob) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.addEventListener('load', () => {
          let img = new Image();
          img.src = reader.result
          img.addEventListener('load', () => resolve(img))
        })
        reader.readAsDataURL(blob)
      })
    },
    imgToCanvas (img) {
      let canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      return canvas
    },
    requestJsSdkConfig: function() {
      let that = this;
      // let agentId = common.config.agentId;
      // let corpId = common.config.corpId;
      let agentId = LocalStorage.getItem("agentId");
      let corpId = LocalStorage.getItem("corpId");
      this.axios.get(api.getJsSdkConfig, {
        params: {
          agentId,
          corpId,
          url: encodeURIComponent(window.location.href.split('#'))
        }
      })
              .then(function (response) {
                console.log(response.data.data);
                if (common.success(response)) {
                  let data = response.data.data;
                  that.wxConfigData = {nonceStr: data.nonceStr, timestamp: data.timestamp, signature: data.signature, appId: data.appId};
                  that.wxConfig(that.wxConfigData)
                } else {
                  alert('获取JsSdkConfig失败：' + response.data.reason);
                }
              })
              .catch(function (error) {
                console.log(error);
                alert('获取JsSdkConfig失败：' + error);
              })
              .then(function () {

              });
    },
    chooseImage: function (item) {
      console.log(item);
      var that = this;
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original'], // '' 'compressed' 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: "normal", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        success: function (res) {
          console.log(res);
          var localIds = res.localIds; // 返回选定照片的本地ID列表，
          var localId = localIds[0];
          that.uploadImage(localId, item);
          // if (that.isIOS()) {
          //   that.showIOSImage(localId, item);
          // } else {
          //   item.image = localId;
          // }
          // andriod中localId可以作为img标签的src属性显示图片；
          // 而在IOS中需通过上面的接口getLocalImgData获取图片base64数据，从而用于img标签的显示
        }
      });
    },
    uploadImage: function (localId, item) {
      var that = this;
      wx.uploadImage({
        localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回图片的服务器端ID
          console.log('serverId:' + serverId);
          that.uploadImageToServer(serverId, item);
        }
      });
    },
    uploadImageToServer: function (serverId, item) {
      let that = this;
          this.$toast.loading({ 
      message: "正在生成图片",
      forbidClick: true,
      duration: 0});
      this.axios.post(api.modifyPlaceAssessItemImage, {
          storeId: this.storeId,
          mediaId: serverId,
          assessId: item.assessId,
          assessItemId: item.id,
          brand: this.brand,
          code: item.placeId,
          actionId:this.actionId
        }
      ).then(function (response) {
                that.$toast.clear();
                console.log('----- uploadImage -----');
                console.log(response,item);
                if (common.success(response)) {
                  that.$toast({message: '调整成功', duration: 1000});
                 setTimeout(() => {
                  that.getAssess()
                 }, 1000);
                  // item.adjustedImage =  response.data.data.image;
                  // that.places.map(v =>{
                  //   if(v.id == item.id){
                  //     v.adjustedImage = response.data.data.image
                  //   }
                  // })
                  // that.$forceUpdate()
                } else {
                  that.$dialog.alert({
                    message:response.data.msg ||  response.data.reason,
                  });
                }
                console.log(response);
              })
              .catch(function (error) {
                console.log(error);
              });
    },
    previewImage: function (url) {
      if(/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)){
        window.previewImage.start({
          current: url, // 当前显示图片的http链接
          urls: [url] // 需要预览的图片http链接列表
        });
      }else{
        wx.previewImage({
          current: url, // 当前显示图片的http链接
          urls: [url] // 需要预览的图片http链接列表
        });
      }
    },
    showIOSImage: function (localId, item) {
      var _this = this
      wx.getLocalImgData({
        localId: localId, // 图片的localID
        success: function (res) {
          var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
          item.image = localData
        }
      });
    },
    compressImage: function (base64) {
      //var reader = new FileReader();
      var img = new Image();
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');

// base64地址图片加载完毕后
      img.onload = function () {
        // 图片原始尺寸
        var originWidth = this.width;
        var originHeight = this.height;
        // 最大尺寸限制
        var maxWidth = 400, maxHeight = 400;
        // 目标尺寸
        var targetWidth = originWidth, targetHeight = originHeight;
        // 图片尺寸超过400x400的限制
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // 更宽，按照宽度限定尺寸
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }

        // canvas对图片进行缩放
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // 清除画布
        context.clearRect(0, 0, targetWidth, targetHeight);
        // 图片压缩
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        // canvas转为blob并上传
        var base64 = canvas.toDataURL('image/jpeg');
        // canvas.toBlob(function (blob) {
        //     // 图片ajax上传
        //     var xhr = new XMLHttpRequest();
        //     // 文件上传成功
        //     xhr.onreadystatechange = function() {
        //         if (xhr.status == 200) {
        //             // xhr.responseText就是返回的数据
        //         }
        //     };
        //     // 开始上传
        //     xhr.open("POST", 'upload.php', true);
        //     xhr.send(blob);
        // }, file.type || 'image/png');
      };
      img.src = base64;
// 文件base64化，以便获知图片原始尺寸
//             reader.onload = function(e) {
//                 img.src = e.target.result;
//             };
    },
    wxConfig: function(data) {
      wx.config({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，企业微信的corpID
        timestamp: data.timestamp, //  必填，生成签名的时间戳
        nonceStr: data.nonceStr, //  必填，生成签名的随机串
        signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ['chooseImage', 'uploadImage', 'previewImage', 'getLocalImgData','downloadImage'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      wx.ready(function () {
        console.log('reday');
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });

      wx.error(function (res) {
        console.log(res);
        alert(JSON.stringify(res));
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    },
  }, 
                
  mounted: function () {
    window.document.title = '待调整'
     this.requestJsSdkConfig()
    // let params = LocalStorage.getItem('params');
    // if (params) {
    //   params = JSON.parse(params);
    //   this.curStore = params.curStore;
    //   this.items = params.items;
    //   this.storeName = this.curStore.name;
    //   this.storeID = this.curStore.storeID;
    //   this.curActivity = params.curActivity
    //   this.assessId = this.$route.query.assessId
    //    this.getAdjustment()
    // }
    this.assessId = this.$route.query.assessId
    this.getAssess()
   
   

  }
}
